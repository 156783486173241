/* eslint-disable global-require */
<template>
  <div id="user-profile">
    <user-header />
    <section>
      <b-row>
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        />
        <b-col
          lg="6"
          cols="12"
          order="1"
          order-lg="2"
        >
          <b-card
            v-for="(data) in posts"
            :key="data.avatar"
          >
            <div
              class="d-flex justify-content-start align-items-center mb-1"
            >
              <!-- avatar -->
              <b-avatar
                :src="data.avatar"
                size="50"
                class="mr-1"
              />
              <!--/ avatar -->
              <div class="profile-user-info">
                <h6 class="mb-0">
                  {{ data.username }}
                </h6>
                <small class="text-muted">{{ data.postTime }}</small>
              </div>
            </div>
            <b-card-text>
              {{ data.postText }}
            </b-card-text>

            <!-- post img -->
            <b-img
              v-if="data.postImg"
              fluid
              rounded
              class="mb-25"
              :src="data.postImg"
            />
            <!--/ post img -->

            <!-- comment box -->
            <b-form-group class="mt-3">
              <b-form-textarea
                rows="3"
                placeholder="Add Comment"
              />
            </b-form-group>
            <!--/ comment box -->

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
            >
              Post Comment
            </b-button>
          </b-card>
        </b-col>
        <b-col
          lg="3"
          cols="12"
          order="3"
        />
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BAvatar, BCard, BCardText, BImg, BLink, BRow, BCol, BAvatarGroup, VBTooltip, BFormTextarea, BButton, BFormGroup, BEmbed,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import UserHeader from './UserHeader.vue'

export default {
  components: {
    UserHeader,
    BAvatar,
    BCard,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BAvatarGroup,
    // eslint-disable-next-line vue/no-unused-components
    BEmbed,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  methods: {
    kFormatter,
  },
  setup() {
    const posts = [
      {
        // eslint-disable-next-line global-require
        avatar: 'https://lorempixel.com/200/200/cats/',
        username: 'Leeanna Alvord',
        postTime: '12 Dec 2018 at 1:16 AM',
        postText:
          'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
        // eslint-disable-next-line global-require
        postImg: 'https://lorempixel.com/600/700/cats/',
        likes: 1240,
        youLiked: true,
        comments: 1240,
        share: 1240,
        likedCount: 140,
      },
      {
        // eslint-disable-next-line global-require
        avatar: 'https://lorempixel.com/250/250/cats/',
        username: 'Rosa Walters',
        postTime: '11 Dec 2019 at 1:16 AM',
        postText:
          'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
        // eslint-disable-next-line global-require
        postImg: 'https://lorempixel.com/550/500/cats/',
        likes: 1240,
        youLiked: true,
        comments: 1240,
        share: 1240,
        likedCount: 271,
      },
      {
        // eslint-disable-next-line global-require
        avatar: 'https://lorempixel.com/150/150/cats/',
        username: 'Charles Watson',
        postTime: '12 Dec 2019 at 1:16 AM',
        postText:
          'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit.',
        likes: 1240,
        youLiked: true,
        comments: 1240,
        share: 1240,
        likedCount: 264,
      },
    ]
    return {
      posts,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
